<template>
	<common-material :multiple="true"/>
</template>

<script>
import commonMaterial from "@/components/common-material/index.vue"

export default {
	components: {
		commonMaterial
	}
}
</script>
